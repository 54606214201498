import {
  Button,
  FlexBox,
  JoonUIColor,
  SPACING,
  Shadow,
  Typography,
  UserRole,
  ParentIcon,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { examplePatientData } from "./exampleChild"
import { SkeletonLoaderRow } from "../../components/loading/SkeletonLoader"
import NotificationDot from "../../components/notificationDot/NotificationDot"
import PatientAlert from "../../components/patientAlert/PatientAlert"
import useUnreadIncidents from "../../hooks/useUnreadIncidents"
import { usePatientGroupsQuery } from "../../networking/queries"
import { FamilyMemberProfile, PatientInfoTab } from "../../types"

const PatientsTable = () => {
  const { data: patientGroups, isLoading } = usePatientGroupsQuery()
  const displayExamplePatient = !patientGroups || patientGroups?.length < 3

  const patients = patientGroups?.flatMap((group: any) =>
    group.profiles.filter((profile: any) => profile.role === UserRole.PATIENT)
  )

  return (
    <FlexBox direction="column" gap={SPACING.space2} justify="unset">
      {isLoading ? (
        <SkeletonLoaderRow
          numRows={3}
          height={106}
          style={{
            borderRadius: SPACING.space2,
            padding: SPACING.space4,
            marginBottom: SPACING.space2,
          }}
        />
      ) : (
        displayExamplePatient && (
          <PatientsTableRow patient={examplePatientData} isExamplePatient />
        )
      )}
      {patients?.map((patient: FamilyMemberProfile, i: number) => (
        <PatientsTableRow patient={patient} key={i} />
      ))}
    </FlexBox>
  )
}

export default PatientsTable

const PatientsTableRow = ({
  patient,
  isExamplePatient = false,
}: {
  patient: FamilyMemberProfile
  isExamplePatient?: boolean
}) => {
  const isMobile = useMediaQuery("(max-width:800px)")
  const navigate = useNavigate()

  const onClickTableRow = () => {
    navigate(`/patients/${patient.user.id}/${PatientInfoTab.PARENT_TRAINING}`)
  }

  return (
    <FlexBox
      direction="column"
      key={patient.user.id}
      style={{
        padding: SPACING.space4,
        borderRadius: SPACING.space2,
        border: `1px solid ${JoonUIColor.border.default}`,
        boxShadow: Shadow.low,
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      {isExamplePatient && (
        <Typography
          variant="bodyBold"
          style={{ color: JoonUIColor.semantic.primary }}
          textAlign="left"
        >
          Sample Patient
        </Typography>
      )}
      <FlexBox
        direction="row"
        wrap={false}
        align="center"
        justify="space-between"
      >
        <FlexBox direction="column" style={{ flex: isMobile ? 1 : 0.3 }}>
          <Typography
            variant="h3"
            textAlign="left"
            style={{ position: "relative" }}
          >
            {patient.user.name}
          </Typography>
        </FlexBox>
        {!isMobile && (
          <FlexBox
            direction="column"
            style={{ width: "fit-content", flex: 0.6 }}
          >
            <FlexBox
              direction="row"
              gap={SPACING.space2}
              align="center"
              wrap={false}
            >
              <ParentIcon />
              <Typography
                variant="bodySmall"
                textAlign="left"
                color={JoonUIColor.text.secondary}
              >
                {/* @ts-ignore */}
                {patient.user.parent?.name || "-"}
              </Typography>
            </FlexBox>
          </FlexBox>
        )}
        <Button
          buttonType="secondary"
          text="Check progress"
          size="small"
          style={{
            whiteSpace: "nowrap",
            padding: `${SPACING.space0} ${SPACING.space3}`,
          }}
          onClick={onClickTableRow}
        />
      </FlexBox>
      <PatientAlert patient={patient} />
      <NewActivityNotification patient={patient} />
    </FlexBox>
  )
}

const NewActivityNotification = ({
  patient,
}: {
  patient: FamilyMemberProfile
}) => {
  const {
    data: unreadIncidents,
    targetBehaviorIncidents,
    dataTaskIncidents,
  } = useUnreadIncidents({ userId: patient.user.id })

  const navigate = useNavigate()

  const onClick = () => {
    if (dataTaskIncidents && dataTaskIncidents?.length > 0) {
      navigate(`/patients/${patient.user.id}/${PatientInfoTab.PARENT_TRAINING}`)
    } else if (targetBehaviorIncidents && targetBehaviorIncidents?.length > 0) {
      navigate(`/patients/${patient.user.id}/${PatientInfoTab.BEHAVIORS}`)
    }
  }

  return (
    <button style={{ padding: 0 }} onClick={onClick}>
      {unreadIncidents && unreadIncidents?.length > 0 && (
        <FlexBox direction="row" gap={SPACING.space1} align="center">
          <NotificationDot />
          <Typography
            variant="caption"
            style={{ fontWeight: 600 }}
            color={JoonUIColor.semantic.destructive}
          >
            New activity
          </Typography>
        </FlexBox>
      )}
    </button>
  )
}
